<template>
    <b-modal
      v-model="value"
      title="Add/Edit Header item"
      ref="item-modal"
      id="item-modal"
    >
      <b-form @submit.prevent="save">
        <b-form-checkbox v-model="item.active" name="active" class="content-switch mb-3" plain switch><b>Active</b></b-form-checkbox>
        <b-tabs content-class="mt-3" nav-class="no-padding">
          <b-tab v-for="(language, index) in languages" :key="index">
            <template #title>
              <country-flag :country="language" size="normal" />
            </template>
            <!-- TITLE  -->
            <b-form-group label="Title">
              <b-form-input
                name="title"
                v-validate="'required|min:3|max:50'"
                v-model="item.title[language]"
              ></b-form-input>
              <div v-if="submitted && errors.has('title')" class="alert-danger">
                {{ errors.first("title") }}
              </div>
            </b-form-group>
            <!-- LINK  -->
            <b-form-group label="Link">
              <b-form-input name="link" v-validate="'required|min:3|max:50'" v-model="item.link[language]" />
              <div v-if="submitted && errors.has('link')" class="alert-danger">
                {{ errors.first("link") }}
              </div>
            </b-form-group>
          </b-tab>
        </b-tabs>
        <!-- ROLES -->
        <b-form-group label="Roles">
          <b-form-checkbox-group plain v-model="item.roles">
            <b-form-checkbox v-for="(role, index) in roles" :key="index" :value="role" :label="role">
              {{role}}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div
          v-if="message"
          class="alert"
          :class="successful ? 'alert-success' : 'alert-danger'"
        >
          {{ message }}
        </div>
        <b-button type="submit" @click="save()">Save</b-button>
        <b-button @click="value = false">Close</b-button>
      </template>
    </b-modal>
</template>

<script>
  export default {
    props: ["value", "item", "items", "index"],
    data() {
      return {
        message: "",
        submitted: false,
        successful: false,
        roles: ["user", "moderator", "admin"]
      };
    },
    watch: {
      value() {
        this.$emit("input", this.value);
      },
    },
    methods: {
      async save() {
        this.message = "";
        this.submitted = true;
        this.$validator.validateAll().then((isValid) => {
          if (!isValid) {
            return;
          }
          if (this.index !== null) {
            this.items[this.index] = this.item;
          } else {
            this.items.push(this.item);
          }
          this.$store.dispatch("Header/update", this.items);
          this.$store.dispatch("Header/fetchAll");
          this.$emit("input", false);
        });
      },
    },
    computed: {
      languages() {
        return this.$store.state.Languages.all;
      },
    }
  }
</script>