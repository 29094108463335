export function translateSetting(
  value: string | { [key: string]: string },
  locale = "nl"
): string {
  if (typeof value === "string") {
    return value;
  }
  if (Object.keys(value).length === 0) {
    return "";
  }
  if (value[locale]) {
    return value[locale];
  }
  return Object.values(value)[0];
}
